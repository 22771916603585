.social-media-div {
    font-size: 2em;
}

.icon-button {
    margin-bottom: 10px;
}

.icon-button i {
    color: white;
    border-radius: 2.6rem;
    cursor: pointer;
    display: inline-block;
    font-size: 1.3rem;
    height: 2.6rem;
    line-height: 2.6rem;
    margin: 0 5px;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 2.6rem;
    margin-bottom: 10px;
    transition: 0.2s ease-in;
}

.facebook i {
    background-color: #3b5998;
}

.linkedin i {
    background-color: #0e76a8;
}

.github i {
    background-color: #333;
}

.gitlab i {
    background-color: #fca326;
}

.google i {
    background-color: #ea4335;
}

.twitter i {
    background-color: #1da1f2;
}

.medium i {
    background-color: #000;
}

.stack-overflow i {
    background-color: #f48024;
}

.instagram i {
    background-color: #c13584;
}

.youtube i {
    background-color: #d13b47;
}

.cv i {
    background-color: #f09b3b;
}

.test i {
    background-color: #35c14c;
}

.scholar i {
    background-color: #438ac4;
}

.researchgate i {
    background-color: #10521b;
}


/* Hover Transition */

.twitter i:hover,
.test i:hover,
.youtube i:hover,
.cv i:hover,
.google i:hover,
.gitlab i:hover,
.github i:hover,
.linkedin i:hover,
.facebook i:hover,
.instagram i:hover,
.stack-overflow i:hover,
.medium i:hover {
    background-color: black;
}


/* Media Query */

@media (max-width: 768px) {
    .social-media-div {
        text-align: center;
    }
}